/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-04 10:59:42
 * @LastEditors: chenzhi
 * @LastEditTime: 2023-06-09 10:00:00
 */
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/exchange' },
  {
    path: '/index',
    name: 'index',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/index/index.vue'),
    meta: {
      title: 'allo认证'
    }
  },
  {
    path: '/certification',
    name: 'certification',
    component: () =>
      import(
        /* webpackChunkName: "certification" */ '@/views/index/certification.vue'
      )
  },
  {
    path: '/collection',
    name: 'collection',
    component: () =>
      import(
        /* webpackChunkName: "collection" */ '@/views/collection/collection.vue'
      )
  },
  {
    path: '/tripartite',
    name: 'tripartite',
    component: () =>
      import(
        /* webpackChunkName: "tripartite" */ '@/views/tripartite/tripartite.vue'
      )
  },
  {
    path: '/videosharing',
    name: 'VideoSharing',
    component: () =>
      import(
        /* webpackChunkName: "videosharing" */ '@/views/share/VideoSharing.vue'
      )
  },
  {
    path: '/ringsharing',
    name: 'RingSharing',
    component: () =>
      import(
        /* webpackChunkName: "ringsharing" */ '@/views/share/RingSharing.vue'
      )
  },
  {
    path: '/wallpapersharing',
    name: 'WallpaperSharing',
    component: () =>
      import(
        /* webpackChunkName: "wallpapersharing" */ '@/views/share/WallpaperSharing.vue'
      )
  },
  {
    path: '/smspush',
    name: 'SmsPush',
    component: () =>
      import(/* webpackChunkName: "smspush" */ '@/views/note/SmsPush.vue')
  },
  {
    path: '/download',
    name: 'Download',
    component: () =>
      import(/* webpackChunkName: "download" */ '@/views/download/Download.vue')
  },
  {
    path: '/downloadapp',
    name: 'Downloadapp',
    component: () =>
      import(
        /* webpackChunkName: "download" */ '@/views/download/Downloadapp.vue'
      )
  },
  {
    path: '/download-app',
    name: 'Download-app',
    component: () =>
      import(
        /* webpackChunkName: "download" */ '@/views/download/Download-app.vue'
      )
  },
  {
    path: '/spread',
    name: 'Spread',
    component: () =>
      import(/* webpackChunkName: "spread" */ '@/views/extension/Spread.vue')
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () =>
      import(/* webpackChunkName: "feedback" */ '@/views/feedback/Feedback.vue')
  },
  {
    path: '/problem',
    name: 'problem',
    component: () =>
      import(/* webpackChunkName: "problem" */ '@/views/feedback/Problem.vue')
  },
  {
    path: '/songlist-share',
    name: 'songlist-share',
    component: () =>
      import(
        /* webpackChunkName: "songlist-share" */ '@/views/share/SonglistShare.vue'
      )
  },
  {
    path: '/dianshi',
    name: 'dianshi',
    component: () =>
      import(/* webpackChunkName: "dianshi" */ '@/views/ott/dianshi.vue')
  },
  {
    path: '/ott-qiantie',
    name: 'ott-qiantie',
    component: () =>
      import(/* webpackChunkName: "ott-qiantie" */ '@/views/ott/OTTqiantie.vue')
  },
  {
    path: '/ott-zanting',
    name: 'ott-zantinge',
    component: () =>
      import(/* webpackChunkName: "ott-zanting" */ '@/views/ott/OTTzanting.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "ott-zanting" */ '@/views/aboutus/about.vue')
  },
  {
    path: '/invitegroup',
    name: 'Invitegroup',
    component: () =>
      import(
        /* webpackChunkName: "invitegroup" */ '@/views/group/Invitegroup.vue'
      )
  },
  {
    path: '/information',
    name: 'Information',
    component: () =>
      import(
        /* webpackChunkName: "invitegroup" */ '@/views/information/Information.vue'
      )
  },
  {
    path: '/message',
    name: 'message',
    component: () =>
      import(
        /* webpackChunkName: "message" */ '@/views/message-landing/Message.vue'
      )
  },
  {
    path: '/instructions',
    name: 'instructions',
    component: () =>
      import(
        /* webpackChunkName: "instructions" */ '@/views/instructions/Instructions.vue'
      )
  },
  {
    path: '/dynamic',
    name: 'dynamic',
    component: () =>
      import(
        /* webpackChunkName: "dynamic" */ '@/views/dynamic/DynamicIndex.vue'
      )
  },
  {
    path: '/preview',
    name: 'preview',
    component: () =>
      import(/* webpackChunkName: "preview" */ '@/views/channel/preview.vue')
  },
  {
    path: '/channel-details',
    name: 'channel-details',
    component: () =>
      import(
        /* webpackChunkName: "channel-details" */ '@/views/channel/ChannelDetails.vue'
      )
  },
  {
    path: '/channel-sharing',
    name: 'channel-sharing',
    component: () =>
      import(
        /* webpackChunkName: "channel-sharing" */ '@/views/channel/ChannelSharing.vue'
      )
  },
  {
    path: '/exchange',
    name: 'exchange',
    component: () =>
      import(
        /* webpackChunkName: "exchange" */ '@/views/bean-exchange/exchange.vue'
      ),
    meta: {
      title: '金豆'
    }
  },
  {
    path: '/question',
    name: 'problem',
    component: () =>
      import(
        /* webpackChunkName: "problem" */ '@/views/bean-exchange/question.vue'
      ),
    meta: {
      title: '金豆问题'
    }
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ '@/views/bean-exchange/inventory.vue'
      ),
    meta: {
      title: '金豆清单'
    }
  },
  {
    path: '/exchange-confirm',
    name: 'exchange-confirm',
    component: () =>
      import(
        /* webpackChunkName: "exchange-confirm" */ '@/views/bean-exchange/exchange-confirm.vue'
      ),
    meta: {
      title: '兑换确认'
    }
  },
  {
    path: '/bind',
    name: 'Bind',
    component: () =>
      import(
        /* webpackChunkName: "exchange-confirm" */ '@/views/bean-exchange/bind.vue'
      ),
    meta: {
      title: '提现绑定'
    }
  },
  {
    path: '/intimacy-rule',
    name: 'intimacy-rule',
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/intimacy/rule.vue'),
    meta: {
      title: '亲密度规则'
    }
  },
  {
    path: '/howMakeMoney',
    name: 'howMakeMoney',
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/index/makeMoney.vue'),
    meta: {
      title: '如何在tili赚钱'
    }
  },
  {
    path: '/paypal',
    name: 'Paypal',
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/paypal/index.vue'),
    meta: {
      title: '支付'
    }
  },
  {
    path: '/diamond',
    name: 'Diamond',
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/diamond/index.vue'),
    meta: {
      title: '钻石充值界面'
    }
  },
  {
    path: '/diamond-record',
    name: 'DiamondRecord',
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/diamond/record.vue'),
    meta: {
      title: '钻石充值记录'
    }
  },
  {
    path: '/deluser',
    name: 'DelDser',
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/del-user/index.vue'),
    meta: {
      title: '删除用户数据'
    }
  },
  {
    path: '/googlePay',
    name: 'googlePay',
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/googlePay_page/index.vue'),
    meta: {
      title: '谷歌支付'
    }
  },
  {
    path: '/codaPayrealPerson',
    name: 'codaPayRealPerson',
    component: () =>
      import(/* webpackChunkName: "inventory" */ '@/views/codaPayrealPerson/index.vue'),
    meta: {
      title: 'coda支付回调'
    }
  }
]

const router = new VueRouter({
  routes
})

export default router

/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-04 10:59:42
 * @LastEditors: chenzhi
 * @LastEditTime: 2023-03-06 15:27:55
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './permission'
import 'normalize.css'
import './styles/index.scss'
import 'reset-css'
import './vant'
import { useDebug } from './utils/index'
import VueI18n from 'vue-i18n'
import * as moment from 'moment'
import { curLanguage, curLanguageDirect } from '@/utils'

// import VConsole from 'vconsole'
// new VConsole()

Vue.prototype.$moment = moment
Vue.prototype.$languageDir = curLanguageDirect()
Vue.config.productionTip = false

useDebug()
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: curLanguage,
  //locale: 'ar',

  // locale: 'ug',
  // fallbackLocale: 'uy', //如果没有找到要显示的语言，则默认显示 ‘en’
  messages: {
    zh: require('./i18n/zh.json'),
    ug: require('./i18n/ug.json'),
    turki: require('./i18n/turki.json'),
    en: require('./i18n/en.json'),
    fr: require('./i18n/fr.json'),
    ar: require('./i18n/ar.json')
  },
  silentTranslationWarn: true // 去除国际化警告
})
document.onselectstart = function() {
  return false
}
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')

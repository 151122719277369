/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-07 11:58:13
 * @LastEditors: Andy
 * @LastEditTime: 2022-09-19 15:25:04
 */
import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'

@Module({ dynamic: true, store, name: 'Loyout' })
class Loyout extends VuexModule {
  title = 'Allo'

  @Mutation
  setTitle(title: any) {
    this.title = title || 'Allo'
    document.title = this.title
  }
}

export const LoyoutModule = getModule(Loyout)

/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-11 14:14:41
 * @LastEditors: chenzhi
 * @LastEditTime: 2023-04-20 10:04:03
 */
let connectWebViewJavascriptBridge: (
  callback: (bridge: Window['WebViewJavascriptBridge']) => void
) => void
if (window.location.href.indexOf('iOS') !== -1) {
  // iOS13 及其以上版本
  connectWebViewJavascriptBridge = function(callback) {
    if (window.WebViewJavascriptBridge) {
      return callback(window.WebViewJavascriptBridge)
    }
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback)
    }
    window.WVJBCallbacks = [callback]
    const WVJBIframe = document.createElement('iframe')
    WVJBIframe.style.display = 'none'
    WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__'
    document.documentElement.appendChild(WVJBIframe)
    setTimeout(function() {
      document.documentElement.removeChild(WVJBIframe)
    }, 0)
  }
  connectWebViewJavascriptBridge(function(bridge) {
    bridge.registerHandler('JS Echo', function(data, responseCallback) {
      console.log('JS Echo called with:', data)
      responseCallback(data)
    })
    bridge.callHandler('ObjC Echo', { key: 'value' }, function responseCallback(
      responseData
    ) {
      console.log('JS received response:', responseData)
    })
  })
} else {
  // 安卓 和 iOS13 以下及其版本
  connectWebViewJavascriptBridge = function(callback) {
    if (window.WebViewJavascriptBridge) {
      callback(window.WebViewJavascriptBridge)
    } else {
      document.addEventListener(
        'WebViewJavascriptBridgeReady',
        function() {
          callback(window.WebViewJavascriptBridge)
        },
        false
      )
    }
  }
  connectWebViewJavascriptBridge(function(bridge) {
    bridge.init()
  })
}

let methodList: string[] = []

export async function callBridgeAsync(
  name: string,
  data: string | number | object = '',
  /**超时清除 */
  overtime = true,
  /**默认10s */
  overtimes = 10000
): Promise<any> {
  if (typeof data === 'object') {
    data = JSON.stringify(data)
  }

  return new Promise((resolve, reject) => {
    if (methodList.length) {
      if (!methodList.includes(name)) {
        return reject({
          code: '404',
          message: `应用异常：${name}未在宿主机注册`
        })
      }
    }

    const timer = overtime
      ? setTimeout(() => {
          console.log(name, '超时')
          reject('超时')
        }, overtimes)
      : 0
    connectWebViewJavascriptBridge(function(bridge) {
      bridge.callHandler(name, data, function(response) {
        if (typeof response == 'string') {
          try {
            response = JSON.parse(response)
          } catch (e) {
            console.log(`事件${name}响应为非json字符串`)
          }
        }
        if (process.env.NODE_ENV != 'production') {
          console.log(`事件${name}响应`, response)
        }
        overtime && clearTimeout(timer)
        if (response?.code && response.code != 200) {
          reject(response)
        }
        resolve(response)
      })
    })
  })
}

function callBridge(name: string, data: string | object = '') {
  if (typeof data === 'object') {
    data = JSON.stringify(data)
  }
  connectWebViewJavascriptBridge(function(bridge) {
    bridge.callHandler(name, data, function(response) {
      console.log(`事件${name}响应`, response)
    })
  })
}

function registerBridge(name: string, fun: Function) {
  connectWebViewJavascriptBridge(function(bridge) {
    bridge.registerHandler(name, function(data, responseCallback) {
      fun(data)
      console.log('JS 注册的方法', data)
      responseCallback(data)
    })
  })
}

export function callAppFunc_IsHiddenNavagationBar() {
  callBridge('appFunc_IsHideNavBar')
}

/**
 * 设置导航颜色
 * @param navColor 16进制颜色 #FFFFFF
 */
export function callAppFunc_setNavColor(navColor: string) {
  callBridge('appFunc_setNavColor', navColor)
}

/**
 * 主动获取token
 */
export function callAppFunc_CacheToken() {
  return callBridgeAsync('appFunc_CacheToken') as Promise<any>
}

/**
 * 设置导航文字颜色
 */
export function callAppFunc_setTitleFontColor(color: string) {
  return callBridgeAsync('appFunc_setTitleFontColor', color) as Promise<any>
}

/**
 * 调用登陆
 * @param type
 */
export function callAppFunc_Jump2Act(type: string) {
  return callBridgeAsync('appFunc_Jump2Act', type) as Promise<any>
}

/**
 * 通知已签到
 */
export function callAppFunc_HasSignIn() {
  return callBridge('appFunc_HasSignIn')
}

/**
 * 下载
 */
export function callAppFunc_startToDownload(url: string) {
  return callBridgeAsync('appFunc_startToDownload', url)
}

/**
 * 下载
 */
export function callAppFunc_startDown(id: string) {
  return callBridgeAsync('appFunc_startDown', id)
}

/**
 * 打开第三方App
 */
export function callAppFunc_webToThirdApp(data: {
  appPackageName: string
  allToAppStore?: boolean
  appData?: any
}) {
  return callBridgeAsync('appFunc_webToThirdApp', data)
}
/**
 * 获取wxcode
 */
export function callAppFunc_getWXCode() {
  return callBridgeAsync('appFunc_getWXCode', '', false)
}

/**
 * 是否安装app
 */
export function callAppFunc_isInstall(packageName: any) {
  return callBridgeAsync('appFunc_isInstall ', packageName)
}

/**
 * 获取App注册的所有方法名
 */
async function callAppFunc_getMethodList() {
  const res = await callBridgeAsync('appFunc_getMethodList')
  methodList = res.data.list
}

callAppFunc_getMethodList()

/**
 * 关闭当前webview弹窗
 */
export function closeWebViewPopUp() {
  return callBridgeAsync('appFunc_closeWebViewPopUp')
}

/**
 * 跳转至用户个人中心
 * @param uid
 */
export function callAppFunc_jumpToPersonalCenter(uid: number | string) {
  return callBridgeAsync('appFunc_jumpToPersonalCenter', String(uid))
}

/**
 * 跳转至直播间/聊天室
 * @param uid
 */
export function callAppFunc_jumpToLiveRoom(uid: number | string) {
  return callBridgeAsync('appFunc_jumpToLiveRoom', String(uid))
}

/**
 * 跳转至礼物弹窗
 * @param uid
 */
export function callAppFunc_jumpToGiftPopUp(id: number | string) {
  return callBridgeAsync('appFunc_jumpToGiftPopUp', String(id))
}

export function callAppFunc_openPersonalDataPopUp(uid: number | string) {
  return callBridgeAsync('appFunc_openPersonalDataPopUp', String(uid))
}

export function callAppFunc_hideNavCloseBtn() {
  return callBridgeAsync('appFunc_hideNavCloseBtn')
}

/**
 * 跳转至问题反馈
 * @returns
 */
export function callAppFunc_navigateFeedBack() {
  return callBridgeAsync('appFunc_navigateFeedBack')
}

/**
 * App内部分享
 * @returns
 */
export function callAppFunc_callShare(data: {
  title: string
  content: string
}) {
  return callBridgeAsync('appFunc_callShare', data)
}

/**
 * 获取设备信息
 * @returns
 */
export function callAppFunc_getSomeParams() {
  return callBridgeAsync('appFunc_getSomeParams') as Promise<any>
}

/**
 *调用原生数据库GET
 * @param data
 * @returns
 */

export function callAppFunc_dbGetForGame(data: {
  name: string
  packageName: string
  versionName: string
  md5: string
}) {
  return callBridgeAsync('appFunc_dbGetForGame', data)
}

/**
 * 安装app
 * @param data
 * @returns
 */
export function callAppFunc_installGameForGame(data: {
  name: string
  packageName: string
  versionName: string
  md5: string
}) {
  return callBridgeAsync('appFunc_installGameForGame', data)
}

/**
 * 打开App
 * @param {*} id
 */
export function callAppFunc_openGameForGame(data: {
  name: string
  packageName: string
  versionName: string
  md5: string
}) {
  return callBridgeAsync('appFunc_openGameForGame', data)
}

/**
 * appFunc_dbSet
 */
export function callAppFunc_dbSet(data: { key: string; value: any }) {
  return callBridgeAsync('appFunc_dbSet', data)
}

/**
 * 下载成功的回调
 * @param fun
 * @returns
 */
export function appFunc_getDownProgress(fun: Function) {
  return registerBridge('jsFunc_getDownProgress', fun)
}

/**
 * 获取相册权限
 * @returns
 */
export function appFunc_sendOpenImage() {
  return callBridgeAsync('appFunc_sendOpenImage')
}

/**
 * 选完图片回调
 * @param fun
 * @returns
 */
export function appFunc_RegisterFinishCb(fun: Function) {
  return registerBridge('jsFunc_FinishCallBack', fun)
}

/**
 * 返回的方法
 * @returns
 */
export function appFunc_callExit() {
  return callBridge('appFunc_callExit')
}

/**
 * allo获取token
 * @returns
 */
export function appFunc_senToken() {
  return callBridgeAsync('appFunc_senToken') as Promise<any>
}

/**
 * allo 支付成功回调
 * @returns
 */
export function appFunc_paySuccess(orderId: string) {
  return callBridgeAsync('appFunc_paySuccess', orderId) as Promise<any>
}
/**
 * allo 支付失败回调
 * @returns
 */
export function appFunc_payFail(data: any) {
  return callBridgeAsync('appFunc_payFail', data) as Promise<any>
}

/**
 * 频道分享
 * @param data
 * @returns
 */
export function appFunc_callShareChannelMsg(data: {
  shareType: number
  channelMsgId: number
  msgTitle: string
  msgCover: string
  htmlUrl: string
  channelId: number
}) {
  return callBridgeAsync('appFunc_callShareChannelMsg', data)
}

/**
 * 频道分享成功回调
 * @param fun
 * @returns
 */
export function appFunc_RegisterShareCb(fun: Function) {
  return registerBridge('jsFunc_ShareCallBack', fun)
}

/**
 *跳到频道首页
 * @param data
 * @returns
 */
export function appFunc_callChannelPage(data: any) {
  return callBridgeAsync('appFunc_callChannelPage', data)
}

/**
 * 调起微信登录界面
 * @returns
 */
export function appFunc_callLogin_wechat() {
  return callBridge('appFunc_callLogin_wechat')
}

/**
 * 注册登录成功回调
 * @param fun
 * @returns
 */
export function appFunc_RegisterLoginCb(fun: Function) {
  return registerBridge('jsFunc_LoginCallBack', fun)
}

/**
 * 拉起谷歌支付
 * @param data
 * @returns
 */
export function appFunc_callGooglePay(data: any) {
  return callBridgeAsync('appFunc_callGooglePay', data)
}

/**
 * 注册谷歌支付成功事件
 * @param fun
 * @returns
 */
export function appFunc_RegisterGooglePaySuccess(fun: Function) {
  return registerBridge('jsFunc_GooglePaySuccess', fun)
}


/**
 * 拉起谷歌支付 --会员购买
 * @param data
 * @returns
 */
export function appFunc_callGooglePayVip(data: any) {
  return callBridgeAsync('appFunc_callGooglePayVip', data)
}

/**
 * 支付回调 --paypal coda
 * @param data 
 * @returns 
 */
export function appFunc_callPayVipBack(data: {
  type:string,
  callBackStatus:number,
  errMsg?:string
}) {
  return callBridgeAsync('appFunc_callPayVipBack', data)
}
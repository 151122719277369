/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-04 11:40:32
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-05-07 10:17:45
 */
import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import { NavigationGuardNext, Route } from 'vue-router'
import { beforeEnter, CommonModule } from './store/modules/common'
import {
  callAppFunc_setTitleFontColor,
  callAppFunc_setNavColor
} from './utils/bridge'
import { LoyoutModule } from './store/modules/layout'
import { getPlatform } from './utils'

NProgress.configure({ showSpinner: false })

/**
 * 路由加载前的处理
 */
export async function globalBeforeRouter(
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) {
  // 模拟加载条
  NProgress.start()

  to.meta!.pageStartTime = new Date().getTime()

  // 路由缓存处理
  if (to.meta!.cache && to.name) {
    CommonModule.addKeepAlive(to.name)
  }

  if (to.meta!.showNavBack) {
    if (from.fullPath === '/') {
      to.meta!.showNavBack = false
    }
  }

  // 如果页面需要token
  if (to.meta!.auth) {
    await beforeEnter()
    // 如果页面需要登录才能访问
    if (to.meta!.login) {
      if (!CommonModule.token) {
        CommonModule.login()
      }
    }
  }

  next()
}

/**
 * 运行平台
 */
const platform = getPlatform()

// 全局路由加载完成需要做的操作
export async function globalAfterRouter(to: Route) {
  // 获取页面加载时间
  to.meta!.pageLoadTime = new Date().getTime() - to.meta!.pageStartTime

  // 关闭自定义加载条
  NProgress.done()

  /**
   * 如果在 kixmix 或 taktak App内打开根据配置修改导航栏
   */
  if (platform && ['allo'].includes(platform)) {
    callAppFunc_setTitleFontColor(to.meta!!.titleColor || '#000000')
    callAppFunc_setNavColor(to.meta!.navColor || '#ffffff')
  }
  LoyoutModule.setTitle(to.meta!.title)
}

router.beforeEach(globalBeforeRouter)

router.afterEach(globalAfterRouter)


















import { Component, Vue } from 'vue-property-decorator'
import { CommonModule } from './store/modules/common'
import { callAppFunc_getSomeParams, appFunc_senToken } from './utils/bridge'

@Component({
  name: 'App'
})
export default class App extends Vue {
  created() {
    this.getToken()
    this.getSomeData()
  }

  async getSomeData() {
    const res = await callAppFunc_getSomeParams()
    console.log(res, 'getSomeData', '////////////////////////////')
    CommonModule.setAppVersion(res.versionName)
    CommonModule.setDeviceId(res.deviceId)
    console.log(CommonModule.deviceId, 'deviceId')
  }

  async getToken() {
    const res = await appFunc_senToken()
    console.log(res, 'getToken', '////////////////////////////')
    CommonModule.setUserId(res.id)
    CommonModule.setToken(res.tokenForJs)
    CommonModule.setUserPhone(res.mobile)
    CommonModule.setUserName(res.name)
    CommonModule.setDeviceModel(res.devicemodel)
    CommonModule.setSysVersion(res.sysversion)
  }
}

/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-07 11:58:13
 * @LastEditors: chenzhi
 * @LastEditTime: 2023-06-07 20:09:22
 */
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from 'vuex-module-decorators'
import store from '@/store'
import { callAppFunc_getSomeParams, callAppFunc_Jump2Act } from '@/utils/bridge'
import Cookie from 'js-cookie'

@Module({ dynamic: true, store, name: 'Common' })
class Common extends VuexModule {
  /**
   * 用户token
   */
  //token = Cookie.get('taktak_token') || '7068c14de28d1c807406ba589463a105'
  token = Cookie.get('taktak_token') || ''

  oldToken = Cookie.get('taktak_old_token') || ''
  /**
   * 用户id
   */
  userId = Cookie.get('taktak_userid') || ''
  userPhone = ''
  userName = ''
  /**
   * 设备id
   */
  deviceId = ''
  @Mutation
  setUserId(userId: string) {
    if (userId) this.userId = userId
  }
  /**
   * 语言
   */
  language = ''
  @Mutation
  setLanguage(language: string) {
    if (language) this.language = language
  }

  /**进入第三方次数 */
  thirddegree = 0
  @Mutation
  setThirddegree(thirddegree: number) {
    if (thirddegree) this.thirddegree = thirddegree
  }

  /**
   * 填写认证信息
   */
  AuthenInfo = {
    name: '',
    pictures: [],
    type: 0,
    auditStatus: 0
  }
  @Mutation
  setAuthenInfo(AuthenInfo: {
    name: string
    pictures: never[]
    type: number
    auditStatus: number
  }) {
    if (AuthenInfo) this.AuthenInfo = AuthenInfo
  }

  title = ''
  @Mutation
  setTitle(title: string) {
    if (title) this.title = title
  }

  @Mutation
  setDeviceId(deviceId: string) {
    if (deviceId) {
      this.deviceId = deviceId
    }
  }

  @Mutation
  setUserPhone(userPhone: string) {
    if (userPhone) this.userPhone = userPhone
  }

  @Mutation
  setUserName(userName: string) {
    if (userName) this.userName = userName
  }
  appVersion = ''
  @Mutation
  setAppVersion(appVersion: string) {
    if (appVersion) this.appVersion = appVersion
  }

  devicemodel = ''
  @Mutation
  setDeviceModel(devicemodel: string) {
    if (devicemodel) this.devicemodel = devicemodel
  }
  sysversion = ''
  @Mutation
  setSysVersion(sysversion: string) {
    if (sysversion) this.sysversion = sysversion
  }

  @Mutation
  setToken(token: string) {
    this.token = token
  }

  userType = ''
  @Mutation
  setUserType(userType: string) {
    this.userType = userType || ''
  }
  isLogin = false

  @Mutation
  setLogin(isLogin: boolean) {
    this.isLogin = isLogin
  }
  @Action
  async getToken() {
    try {
      const res = await callAppFunc_getSomeParams()
      console.log(res)

      if (res.isLogin) {
        this.setLogin(true)
      }
      this.setToken(res.newToken)
      this.setUserId(res.userId)
      this.setDeviceId(res.deviceId)
      this.setUserType(res.userType)
      this.setAppVersion(res['app-version'])
      return true
    } catch (error) {
      console.error(error)
    }
  }

  keepAlive: string[] = []

  @Mutation
  addKeepAlive(name: string) {
    if (!this.keepAlive.includes(name)) {
      this.keepAlive.push(name)
    }
  }

  @Action
  login() {
    callAppFunc_Jump2Act('1')
  }
}

export const CommonModule = getModule(Common)
export async function beforeEnter() {
  if (CommonModule.token || document.cookie.includes('taktak_token')) {
    CommonModule.getToken()
    return
  } else {
    console.time('getToken')
    await CommonModule.getToken()
    console.timeEnd('getToken')
    return
  }
}

/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-08 11:54:30
 * @LastEditors: chenzhi
 * @LastEditTime: 2023-02-21 14:14:33
 */
import Vue from 'vue'
import {
  Toast,
  Button,
  Tab,
  Tabs,
  Popup,
  Image as VanImage,
  Collapse,
  CollapseItem,
  List,
  PullRefresh,
  Cell,
  CellGroup,
  Icon,
  NoticeBar,
  Field,
  Switch,
  Uploader,
  NavBar,
  Picker,
  Sticky,
  RadioGroup,
  Radio,
  Empty,
  Overlay,
  Checkbox,
  ActionSheet,
  DropdownMenu,
  DropdownItem,
  Loading
} from 'vant'

Vue.use(Toast)
Vue.use(Button)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Popup)
Vue.use(VanImage)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Icon)
Vue.use(NoticeBar)
Vue.use(Field)
Vue.use(Switch)
Vue.use(Uploader)
Vue.use(NavBar)
Vue.use(Picker)
Vue.use(Sticky)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Empty)
Vue.use(Overlay)
Vue.use(Checkbox)
Vue.use(ActionSheet)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
.use(Loading)
